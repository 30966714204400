<template>
  <div >
    <el-backtop></el-backtop>
    <img src="../../assets/image/新闻资讯/资讯中部广告.png" alt="" />
    <div class="info-content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/newsConsult' }"
          >返回 |
        </el-breadcrumb-item>
      </el-breadcrumb>
      <h1>行业新闻</h1>
      <p>随着新一代信息技术飞速发展、信息化人才和专家的不断辈出、行业数字化转型升级不断推进，加上互联网公司的各行业渗透。目前，社会上各种新的名词和新的概念层出不穷、目不暇接。</p>
      <p>国家及行业层面：有智慧城市、智慧交通、行业大脑、新基建等；</p>
      <p>企事业层面：有数字化转型、共享经济、互联网经济、数字经济、数字总部等；</p>
      <p>技术层面有物联网、云计算、大数据、5G、人工智能、机器学习、深度学习、知识图谱、卫星技术、区块链等；</p>
      <p>数据层面有数据仓库、数据集市、大数据平台、数据湖、数据中台、数据底座等。</p>
      <p>总之是你方唱罢我登场，各种概念满天飞，尤其是以“数据”为标签的风暴，刮得尤其猛烈，下面就本着了解这些名词概念的由来和求同存异的精神来盘点一下他们的含义、区别以及行业应用。</p>
      <h2>基本概念</h2>
      <h4>1、数据仓库</h4>
        <p>1988年，为解决企业的数据集成问题，IBM的两位研究员（Barry Devlin和Paul Murphy）创造性地提出了一个新的术语：数据仓库（Data Warehouse）。</p>
        <p>到了1992年，后来被誉为“数据仓库之父”的比尔.恩门（Bill Inmon）给出了数据仓库的定义：数据仓库(Data Warehouse)是一个面向主题的（Subject Oriented）、集成的（Integrated）、相对稳定的（Non-Volatile）、反映历史变化的（Time Variant）数据集合，用于支持管理决策和信息的全局共享。</p>
        <p>对于数据仓库的概念我们可以从两个层次予以理解：</p>
        <p>首先，数据仓库用于支持决策,面向分析型数据处理，它不同于企业现有的操作型数据库；</p>
        <p>其次，数据仓库是对多个异构的数据源有效集成，集成后按照主题进行了重组，并包含历史数据，而且存放在数据仓库中的数据一般不再修改。</p>
        <p>主题：是指用户使用数据仓库进行决策时所关心的重点方面；所谓面向主题，是指数据仓库内的信息是按主题进行组织的，而不是像业务支撑系统那样是按照业务功能进行组织的。</p>
        <p>集成：是指数据仓库中的信息不是从各个业务系统中简单抽取出来的，而是经过一系列加工、整理和汇总的过程，因此数据仓库中的信息是关于整个内部一致的全局信息。</p>
        <p>随时间变化：是指数据仓库内的信息并不只是反映当前的状态，而是记录了从过去某一时点到当前各个阶段的信息。通过这些信息，可以对其发展历程和未来趋势做出定量分析和预测。</p>
        <p>数据仓库系统除了包含分析产品本身之外，还包含数据集成、数据存储、数据计算、门户展现、平台管理等其它一系列的产品。</p>
        <p>应用场景：一般都是作为商业智能系统、数据仪表盘等可视化报表服务的数据源。</p>
        <h4>2、数据集市</h4>
        <p>数据仓库之父比尔·恩门说过一句话叫“IT经理们面对最重要的问题就是到底先建立数据仓库还是先建立数据集市”，这足以说明分析清楚这两者之间的关系是十分重要而迫切的。</p>
        <p>数据集市可以理解为是一种"小型数据仓库"，它只包含单个主题，且关注范围也非全局。数据集市可以分为两种:</p>
        <p>独立数据集市：它有自己的源数据库和ETL架构；</p>
        <p>非独立数据集市：它没有自己的源系统，它的数据来自数据仓库。当用户或者应用程序不需要/不必要/不允许用到整个数据仓库的数据时，非独立数据集市就可以简单为用户提供一个数据仓库的子集。</p>
        <p>数据集市是一个结构概念，它是企业级数据仓库的一个子集，主要面向部门级业务，并且只面向某个特定的主题。</p>
        <p>应用场景：数据集市是数仓之上更聚焦的业务主题合集，更偏向于应对业务数据快速高效应用的需求，一般用于商业智能系统中探索式和交互式数据分析应用。</p>
        <h4>3、数据湖</h4>
        <p>Pentaho首席技术官James Dixon提出了“数据湖”一词。它把数据集市描述成一瓶水。而数据湖更像是在自然状态下的水，数据流从源系统流向这个湖。用户可以在数据湖里校验，取样或完全的使用数据。</p>
        <b>数据湖有以下特点：</b>
        <p>从源系统导入所有的数据，没有数据流失；</p>
        <p>数据存储时没有经过转换或只是简单的处理；</p>
        <p>数据转换和定义schema 用于满足分析需求。</p>
        <p>数据湖（Data Lake）是一个存储企业的各种各样原始数据的大型仓库，其中的数据可供存取、处理、分析及传输。</p>
        <p>数据湖是以其自然格式存储的数据的系统或存储库，通常是对象blob或文件。数据湖通常是企业所有数据的单一存储，包括源系统数据的原始副本，以及用于报告、可视化、分析和机器学习等任务的转换数据。</p>
        <p>数据湖可以包括来自关系数据库（行和列）的结构化数据，半结构化数据（CSV，日志，XML，JSON），非结构化数据（电子邮件，文档，PDF）和二进制数据（图像，音频，视频）。</p>
        <p>目前，Hadoop是最常用的部署数据湖的技术，所以很多人会觉得数据湖就是Hadoop集群，这是错误的！</p>
        <p>数据湖是一个数据存储理念，存储企业各种各样的原始数据的大型仓库，包括结构化、非结构、二进制图像、音频、视频等等；而Hadoop是用于实现这个概念的技术手段。</p>
        <p>应用场景：以大数据技术为基础有多样化数据结构海量大数据存储需求，也可作为数据仓库或者数据集市的数据源。</p>
        <h4>4、数据中台</h4>
        <p>“中台”这个概念，是相对于前台和后台而生，是前台和后台的链接点，将业务共同的工具和技术予以沉淀。</p>
        <p>数据中台是指数据采集交换、共享融合、组织处理、建模分析、管理治理和服务应用于一体的综合性数据能力平台，在大数据生态中处于承上启下的功能，提供面向数据应用支撑的底座能力。</p>
        <p>广义上来给数据中台一个定义：“聚合和治理跨域数据，将数据抽象封装成服务，提供给前台以业务价值的逻辑概念”。中台目标:</p>
        <p>把当前系统中各个业务的前端应用与后端服务解耦。</p>
        <p>将各个功能中的服务能力进行梳理、并沉淀。</p>
        <p>将重复、类似的服务进行整合。</p>
        <p>在单个服务的完善和增强的过程中注意服务的通用性，避免其他相似“双胞胎”服务的出现。</p>
        <p>由于服务能力的集中管控，很大程度会促进我们一体化运维的能力，但在“大中台、小前台”的模式下，每一个服务都负责对N多个前端业务应用提供支持，这就要求运维在信息安全、备份、监控等方面要有更强的能力。</p>
        <p>判断是不是中台，还要回到中台要解决的问题上，一切以“以用户为中心的持续规模化创新”为目的，将后台各式各样的资源转化为前台易于使用的能力，帮助我们打赢这场以用户为中心的战争的平台，我们都可以称之为中台。</p>
        <p>中台战略核心是数据服务的共享。中台战略并不是搭建一个数据平台，但是中台的大部分服务都是围绕数据而生，数据中台是围绕向上层应用提供数据服务构建的，中台战略让数据在数据平台和业务系统之间形成了一个良性的闭环，也就是实现应用与数据之间解藕，并实现紧密交互。</p>
        <p>敏捷前台：一线作战单元，强调敏捷交互及稳定交付的组织能力建设。</p>
        <p>业务中台：能力固化与赋能，固化通用能力，赋能前线部队，提升配置效率，加快前线响应，产品化业务化，开辟全新生态。</p>
        <p>数据中台：资产整合与共享，整合多维数据，统一资产管理，连通数据孤岛，共享数据资源，深入挖掘数据，盘活资产价值。</p>
        <p>稳定后台：以共享中心建设为核心，为前中台提供专业的内部服务支撑。</p>
        <p>阿里巴巴在2015年12月进行组织升级，就是“大中台，小前台”的模式。主要的思路是打破原来树状结构，小前台距离一线更近，业务全能，这样便于快速决策、敏捷行动；支持类的业务放在中台，扮演平台支撑的角色。</p>
        <p>数据中台整体技术架构上采用云计算架构模式，将数据资源、计算资源、存储资源充分云化，并通过多租户技术进行资源打包整合，并进行开放，为用户提供“一站式”数据服务。</p>
        <p>利用大数据技术，对海量数据进行统一采集、计算、存储，并使用统一的数据规范进行管理，将企业内部所有数据统一处理形成标准化数据，挖掘出对企业最有价值的数据，构建企业数据资产库，提供一致的、高可用大数据服务。</p>
        <p>数据中台不是一套软件，也不是一个信息系统，而是一系列数据组件的集合，企业基于自身的信息化建设基础、数据基础以及业务特点对数据中台的能力进行定义，基于能力定义利用数据组件搭建自己的数据中台。</p>
        <p>数据中台是一个逻辑概念，为业务提供服务的主要方式是数据API，它包括了数据仓库，大数据、数据治理领域的内容。</p>
        <p>应用场景：将数据服务化提供给业务系统，目的是将数据能力渗透到业务各个环节，不限于决策分析。</p>
        <h4>5、数据底座</h4>
        <p>通过数据底座，主要可以实现如下目标：</p>
        <p>统一管理结构化、非结构化数据。将数据视为资产，能够追溯数据的产生者、业务源头以及数据的需求方和消费者等；</p>
        <p>打通数据供应通道，为数据消费提供丰富的数据原材料、半成品以及成品，满足自助分析、数字化运营等不同场景的数据消费需求；</p>
        <p>确保数据完整、一致、共享。监控数据全链路下的各个环节的数据情况，从底层数据存储的角度，诊断数据冗余、重复以及“僵尸”问题，降低数据维护和使用成本；</p>
        <p>保障数据安全可控。基于数据安全管理策略，利用数据权限控制，通过数据服务封装等技术手段，实现对涉密数据和隐私数据的合法、合规地消费。</p>
        <p>华为提出数据底座由数据湖、数据主题联接两层组成，将内外部的数据汇聚到一起，并对数据进行重新的组织和联接，为业务可视化、分析、决策等提供数据服务。</p>
        <p>数据湖是逻辑上各种原始数据的集合，除了“原始”这一特征外，还具有“海量”和“多样”（包含结构化、非结构化数据）的特征。</p>
        <p>数据湖保留数据的原格式，原则上不对数据进行清洗、加工，但对于数据资产多源异构的场景需要整合处理，并进行数据资产注册。</p>
        <p>数据主题联接是对数据湖的数据按业务流/事件、对象/主体进行联接和规则计算等处理，形成面向数据消费的主题数据，具有多角度、多层次、多粒度等特征，支撑业务分析、决策与执行。</p>
        <p>基于不同的数据消费诉求，主要有多维模型、图模型、指标、标签、算法模型5种数据联接方式。</p>
        <p>应用场景：实时融合交通基础设施静态数据、人车路动态数据和交通管理监测数据的数据底座构建成智慧交通一张图，可精准、实时、全面支持交通运输各场景应用，包括城市交通拥堵治理、交通应急、交通规划、养护运维等多个方面。</p>
        <h4>6、数据大脑</h4>
        <p>数据大脑是集成物端硬件、智能算法、行业知识等系列化系统化的集成成果，简单来说，人工智能和大数据就如同一个硬币的两面，大数据就是人工智能发挥作用的重要燃料，人工智能的算法又是让大数据燃料能够产生动力的发动机，二者缺一不可。</p>
        <p>而数据大脑，就是希望通过更强大的行业数据获取能力和领域知识的计算能力来实现数据驱动的涌现智能，让信息系统具备数据获取的能力，具备行业知识的理解能力，以及进行深度的分析及处理能力。
数据大脑可以应用于很多方面，例如交通运输、健康医疗、环保领域、公共安全、城市治理等等，比如在交通运输领域的数据大脑，可以通过对多源数据的感知，自动对交通运行情况进行实时监测和动态分析，并及时做出精准预判和处置。</p>
        <p>通过不断完善数据大脑的领域知识体系，可以让数据大脑可以更好地服务于政府决策、城市治理、以及很多智慧产业的发展。
从技术层面看数据大脑的构成：它包括大脑平台(包括计算资源平台、数据资源平台、算法服务平台)、行业系统、超级应用(架构于大脑平台和行业系统之上的综合性应用)、中枢应用(支撑建设基于城市数据大脑的创新应用)等。</p>
        <p>应用场景：城市数据大脑、医疗数据大脑、综合交通数据大脑等。</p>
        <h2>横向比较</h2>
        <p>数据集市通常是数据仓库的子集；它的数据通常来自数据仓库，尽管还可以来自其他来源。
数据集市的数据专门针对特定的用户，以便他们能够快速找到所需的数据。
通常，数据保存在那里用于特定用途，例如财务分析。</p>
        <p>数据集市也比数据仓库小得多，它们可以容纳数十千兆字节，相比之下，数据仓库可以存储数百千兆字节到PB级数据，并可用于数据处理。
数据集市可从现有数据仓库或其他数据源系统构建，你只需设计和构建数据库表，使用相关数据填充数据库表并决定谁可以访问数据集即可。</p>
        <p>数据湖作为一个集中的存储库，可以在其中存储任何形式（结构化和非结构化）、任意规模的数据。
在数据湖中，可以不对存储的数据进行结构化，只有在使用数据的时候，再利用数据湖强大的大数据查询、处理、分析等组件对数据进行处理和应用。
因此，数据湖具备运行不同类型数据分析的能力。数据底座由数据湖、数据主题联接两层组成，将内外部的数据汇聚到一起，并对数据进行重新的组织和联接。</p>
        <p>数据中台从技术的层面承接了数据湖的技术，通过数据技术，对海量、多源、多样的数据进行采集、处理、存储、计算，同时统一标准和口径，把数据统一之后，以标准形式存储，形成大数据资产层，以满足前台数据分析和应用的需求。
数据中台更强调应用，离业务更近，强调服务于前台的能力，实现逻辑、算法、标签、模型、数据资产的沉淀和复用，能更快速的相应业务和应用开发的需求，可追溯，更精准。</p>
        <p>数据大脑汇聚海量数据，利用云计算能力，通过大数据、人工智能等技术支撑各行业系统有效运行，有效提升系统能级。
进行跨部门、跨领域、跨区域的即时数据处理，实现数据融合创新，协调各个职能系统，致力于解决综合性问题。
数据大脑的大脑平台、行业系统和各区域中枢之间在数据资源层对接，形成完整的数据资源平台。
大脑平台的算法服务将为行业系统、各区域中枢(及其应用)提供智能化支持。</p>
        <h2>交通应用</h2>
        <h4>交通数据中台：</h4>
        <p>解决可提供从交通信息接入到计算应用的全链路智能资源构建与管理能力，帮助交通客户打破信息孤岛、融合全域信息，在把资源统一之后，会快速形成数据资产、挖掘计算价值，进而赋能交通业务，为客户提供高效服务。
这些服务跟交通客户的业务有较强的关联性，是交通企业业务和资源的沉淀，其不仅能降低重复建设、减少烟囱式协作的成本，也是差异化竞争优势所在，助力交通行业数字化转型及智能应用的创新和推广。
主要代表：阿里数据中台、滴滴数据中台、腾讯数据中台、华为数据中台、百度智慧交通数据中台等。</p>
        <h4>交通数据湖：</h4>
        <p>按照建湖-引水-用水的建设三部曲，通过整体布局构建信息基础设施，应用云计算、大数据、物联网、移动应用、人工智能等新一代技术，汇聚并深度整合交通多元数据，实现数据的协同应用，打造综合交通大脑。
建成后的综合交通数据湖可发挥信息融合、协同共享、智能分析与数据建模、信息服务扩展等能力。
主要代表：华为数据湖、阿里云数据湖，易华录数据湖。</p>
        <h4>交通数据底座：</h4>
        <p>作为交通精细化治理数据底座典型代表有交通一张图，它是面向全国、省级、市县级交通运输管理及企事业单位所提供的包括数据处理、图层制作、服务共享、部署运维等在内的一体化解决方案，以交通地理信息服务为核心。
基于GIS+遥感等空间基础数据及交通业务数据，开发全面贴合交通运输业务的“一张图”信息服务平台，具有信息内容丰富、更新维护及时、应用便捷、共建共享等特点。
可实现动态数据/静态数据一体化的极致可视化展示以及业务数据资源的实时联动，让数据由“静”变“动”，有效盘活交通运输行业地理信息数据资源，增强系统间地理信息数据资源流动性，提升数据综合利用能力，最终实现数据价值最大化，为相关平台及应用系统的建设提供支撑。
主要代表：百度智慧交通地图、中咨集团高分交通一张图、华为数据底座、腾讯全息交通数据底座、世纪高通数字化交通位置底座等。</p>
        <h4>交通数据大脑：</h4>
        <p>主要通过将先进的信息管理、数据通讯传输、电子传感、控制及计算机等技术有效的集成运用于整个地面交通管理系统而建立的一种在大范围内、全方位发挥作用的实时、准确、高效的综合交通管理控制系统，实现了交通管理的动态化、全局化、自动化、智能化。
交通治理的核心问题就是出行时空资源的优化配置交通数据大脑应用在交通治理上，就是从现状感知、交通机理分析、预测模拟和公共服务等角度，来构建交通综合治理的总体框架。
首先，项目的出发点在于交通现状感知。通过各种数据的交通人物下的分析，能够对现在的交通运行进行全时空的精确刻画。基于此，建立起一套对于交通运行全面的监测体系和预警体系。</p>
        <p>其次是交通治理机理的分析。交通运行的深层次影响机制是非常复杂的。大数据技术的应用，给我们传统规划提供了大量的不同于以往的手段，可以去深层次挖掘和分析交通产生的原因和机理。在这样的理论基础上，构建一些新的预测模型。这些模型可以对交通的下一步运行态势，做到精确的预测、预判和模拟。
最后是公众服务。通过这些技术手段，为市民的出行多元、多层次的需求下，提供不同的解决策略。</p>
        <p>主要代表：阿里、海康、华为、腾讯等建设的交通数据大脑或城市大脑。</p>
        <h2>总结和建议</h2>
        <p>所有行业或单位的数字化转型或智慧管理都必须做这些吗？其实对于一个单位或者一个行业来说，选择哪种平台？答案是没有最好，只有最合适。
我们首先应该梳理清楚自己单位或行业管理的数据资产的基本情况、数据服务的需求情况、数据治理的能力情况。
再根据成熟度能力模型评估，正确定位自己单位或行业未来发展情况。
无论是建立数据仓库、数据中台还是数据大脑都是要以业务目标为主，以满足业务发展为第一优先，不要为了做基础设施而做基础设施。
一定要以能解决业务诉求为最终目的，一定切莫盲目跟风，尤其在“十四五”之初，也是各行业规划和顶层设计形成阶段，要避免炒作概念或受制于概念，应尽量从满足需求和解决问题角度去分析、统筹和安排项目。
总之一句话“不轻易相信概念，没有最好的方案，只有最合适的途径！”</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.push('/newsConsult')
    }
  }
}
</script>
<style scoped lang="less">
.el-breadcrumb {
  display: inline-block;
}
h1 {
  display: inline-block;
  font-size: 40px;
  color: #333;
  margin: 0 0 50px 30px;
  border-bottom: 2px solid #3468d2;
}
.info-content {
  margin: 82px 160px 60px;
}
h2 {
  font-size: 30px;
  line-height: 50px;
  color: #111;
  text-align: center;
  margin: 15px 0;
}
h4{
  font-size: 25px;
  line-height: 40px;
  color: #333;
  margin: 15px 0;
}
h5{
  font-size: 22px;
  line-height: 40px;
  color: #333;
  margin: 12px 0;
}
h6{
  font-size: 20px;
  line-height: 35px;
  color: #333;
  margin: 10px 0;
}
b{
  font-size: 18px;
  line-height: 30px;
  color: #333;
  margin: 10px 0;
}
#author{
    text-align: center;
}
p {
  font-size: 18px;
  line-height: 30px;
  text-indent: 2em;
  color: #333;
  margin: 10px 0;
}
.el-page-header {
  width: 200px;
  margin: 50px 0;
}
.el-page-header__content::after {
  content: '';
  display: block;
  width: 4%;
  height: 2px;
  margin-top: 8px;
  background-color: red;
}
.intro {
  margin-top: 20px;
  font-size: 30px;
  line-height: 72px;
  color: #333;
  text-indent: 2em;
}
</style>
